import { useState } from "react";

import { dietContent } from "@/API/pets";

import { DietProps } from "./DietComparison";

export const useDietComparison = ({
	diet,
	name,
	gender,
	allDiets,
}: DietProps) => {
	const [food, setFood] = useState(diet || "kibble biscuits");

	const pronoun = gender === "male" ? "him" : "her";

	const contentArgs: [string, string, string] = allDiets
		? [food, "your pet", "them"]
		: [food, name || "your pet", pronoun || "them"];

	const content = dietContent.apply(null, contentArgs);

	return { setFood, food, content };
};
