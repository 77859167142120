import c from "classnames";
import slugify from "react-slugify";
import { Image } from "react-datocms";

import { TimelineSection } from "@/types/page";
import { SplitHeading } from "@/components/FormElements/SplitHeading";

import Styles from "./timeline.module.scss";

type Props = {
	content: TimelineSection;
};

export const Timeline = ({ content }: Props) => {
	return (
		<section id={content.id} className={c("sectionSpacing", Styles.timeline)}>
			{content.timelinePoints?.map((point, key) => (
				<div
					className={c(Styles.timelinePoint, {
						[Styles.textOnLeft]: point.textOnLeft,
						[Styles.textCentered]: !point.image,
						[Styles["sideIcons-" + slugify(point.sideIcons)]]: point.sideIcons,
					})}
					key={key}
				>
					<div className="container">
						<div className={Styles.pointContent}>
							{point.date && <h4 className={Styles.pointDate}>{point.date}</h4>}
							{point?.heading?.[0]?.headingLine1 && (
								<>
									{point.heading[0].split ? (
										<SplitHeading
											heirarchy={point.heading[0].sizing}
											line1={point.heading[0].headingLine1}
											line2={point.heading[0].headingLine2}
											colour={point.heading[0].secondaryLineColour}
										/>
									) : (
										<h2>{point.heading[0].headingLine1}</h2>
									)}
								</>
							)}
							{point?.content && (
								<span
									className={c("paraLarge", Styles.contentText)}
									dangerouslySetInnerHTML={{ __html: point.content }}
								/>
							)}
						</div>
						{point.image && (
							<div
								className={c(Styles.image, {
									[Styles.zigZag]: point.imageBorder === "zigzag",
									[Styles.letter]: point.imageBorder === "letter",
								})}
							>
								<Image data={point.image.responsiveImage} />
							</div>
						)}
					</div>
				</div>
			))}
		</section>
	);
};
