import c from "classnames";

import { SlapLabelVariants } from "@/types/layout";
import { QuickIntoFunnelWidget } from "@/types/page";

import Styles from "./quickFunnelWidget.module.scss";
import { useQuickFunnelWidget } from "./useQuickFunnelWidget";

import { Button } from "../FormElements/Button";
import { Input, Select } from "../FormElements";
import { Icon, IconOptions } from "../FormElements/Icon";
import { SlapLabel } from "../FormElements/SlapLabel";
import { Radio } from "../FormElements/Radio";

type Props = {
	widget: QuickIntoFunnelWidget;
};

export const QuickFunnelWidget = ({ widget }: Props) => {
	const {
		breedOptions,
		onChangeBreedSelect,
		onChangeDietSelect,
		breedValue,
		register,
		handleSubmit,
		onSubmit,
		breedCount,
		dietOptions,
		dietValue,
		monthsValue,
		yearsValue,
		getValues,
		onChangeYear,
		onChangeMonth,
		errors,
		renderError,
		customValue,
		onChangeCustom,
	} = useQuickFunnelWidget({ widget });

	const pluralBreed =
		breedCount?.name[breedCount.name.length - 1] === "s"
			? breedCount.name
			: `${breedCount?.name}s`;

	return (
		<div
			className={c(Styles.widget, {
				[Styles.widgetLight]: widget.hasDarkBackground,
				[Styles.widgetCustom]: widget.widgetType === "custom",
			})}
		>
			<p>{widget.title}</p>
			<form onSubmit={handleSubmit(onSubmit)}>
				{widget.widgetType === "custom" && (
					<Radio
						name="custom"
						options={[
							{
								id: "yes",
								label: "Yes",
							},
							{
								id: "no",
								label: "No",
							},
						]}
						id="custom"
						register={register}
						errors={errors}
						value={customValue}
						validate={{ required: true }}
						noCircle
						onChange={onChangeCustom}
						errorMessages={{
							required: "Please select an option.",
						}}
					/>
				)}
				{widget.widgetType === "breed" && (
					<Select
						name="breed"
						id="breed"
						register={register}
						placeholder="e.g. Cockapoo"
						options={breedOptions}
						validate={{ required: false }}
						onChange={onChangeBreedSelect}
						value={breedValue}
						type="search"
						errors={errors}
						floatingErrors
						inline
						leftAlign
					/>
				)}
				{widget.widgetType === "name" && (
					<Input
						name="name"
						id="name"
						register={register}
						placeholder="e.g. Teddy"
						errors={errors}
						floatingErrors
						validate={{
							maxLength: 12,
						}}
						leftAlign
					/>
				)}
				{widget.widgetType === "diet" && (
					<Select
						name="diet"
						id="diet"
						register={register}
						placeholder="Please select"
						options={dietOptions}
						validate={{ required: false }}
						onChange={onChangeDietSelect}
						value={dietValue}
						errors={errors}
						floatingErrors
						inline
						leftAlign
					/>
				)}
				{widget.widgetType === "age" && (
					<div className={Styles.twoFields}>
						<Input
							name="years"
							label={"Years"}
							type="tel"
							id="years"
							onChange={onChangeYear}
							register={register}
							labelType="small"
							value={yearsValue}
							errors={errors}
							showErrors={false}
							validate={{
								validate: (value) => {
									return true;
								},
								//@ts-ignore
								pattern: /^[0-9]*$/,
								maxLength: 2,
								deps: ["months"],
							}}
						/>
						<Input
							name="months"
							label={"Months"}
							id="months"
							onChange={onChangeMonth}
							type="tel"
							register={register}
							labelType="small"
							value={monthsValue}
							errors={errors}
							showErrors={false}
							validate={{
								validate: (value) => {
									let success = true;
									if (getValues().years && parseInt(value) > 11) {
										success = false;
									}
									return success;
								},
								// @ts-ignore
								pattern: /^[0-9]*$/,
								maxLength: 2,
								min: getValues().years ? 0 : 1,
								deps: ["years"],
							}}
						/>
					</div>
				)}
				<Button type="submit" width="relative">
					{widget.buttonText}
				</Button>
			</form>
			{(errors["months"] || errors["years"]) && (
				<div className={Styles.widgetError}>
					<SlapLabel tiny variant={SlapLabelVariants.errors}>
						{renderError()}
					</SlapLabel>
				</div>
			)}
			{widget.widgetType === "custom" &&
				customValue === "yes" &&
				widget.yesInfocardContent && (
					<div className={Styles.infoBox}>
						{widget.yesInfocardIcon && (
							<Icon
								icon={widget.yesInfocardIcon as IconOptions}
								width="72"
								height="72"
							/>
						)}
						<div>
							<h4>{widget.yesInfocardTitle}</h4>
							<p>{widget.yesInfocardContent}</p>
						</div>
					</div>
				)}
			{widget.widgetType === "custom" &&
				customValue === "no" &&
				widget.noInfocardContent && (
					<div className={Styles.infoBox}>
						{widget.noInfocardIcon && (
							<Icon
								icon={widget.noInfocardIcon as IconOptions}
								width="72"
								height="72"
							/>
						)}
						<div>
							<h4>{widget.noInfocardTitle}</h4>
							<p>{widget.noInfocardContent}</p>
						</div>
					</div>
				)}
			{widget.widgetType === "breed" && breedCount && (
				<div className={Styles.infoBox}>
					<Icon icon={"IconFunnelHealth"} width="72" height="72" />
					<div>
						<h4>{pluralBreed} love Pure</h4>
						{breedCount.count < 200 && (
							<p>
								Loads of {pluralBreed} are already enjoying a healthy lifestyle
								with Pure.
							</p>
						)}
						{breedCount.count > 200 && breedCount.count < 500 && (
							<p>
								Hundreds of {pluralBreed} are already enjoying a healthy
								lifestyle with Pure.
							</p>
						)}
						{breedCount.count > 500 && (
							<p>
								We've helped{" "}
								{(Math.floor(breedCount.count / 100) * 100).toLocaleString()}{" "}
								{pluralBreed} switch to a deliciously healthy new food.
							</p>
						)}
					</div>
				</div>
			)}
			{widget.widgetType === "diet" && dietValue && (
				<div className={Styles.infoBox}>
					<Icon icon="GuidePour" width="72" height="72" />
					<div>
						{dietValue === "Other or mixed" ? (
							<h4>Mixing food is easy with Pure</h4>
						) : (
							<h4>Switching to Pure is easy</h4>
						)}
						{dietValue === "Kibble" && (
							<p>
								Over 20,000 dogs are enjoying a healthy diet with Pure after
								switching from kibble.
							</p>
						)}
						{dietValue === "Wet food" && (
							<p>
								Over 10,000 dogs are enjoying a healthy diet with Pure after
								switching from wet food.
							</p>
						)}
						{dietValue === "Raw" && (
							<p>
								Over 3,000 dogs are enjoying a healthy diet with Pure after
								switching from raw.
							</p>
						)}
						{dietValue === "Home prepared" && (
							<p>
								Over 3,000 dogs are enjoying a healthy diet with Pure after
								switching from home prepared.
							</p>
						)}
						{dietValue === "Pre-cooked" && (
							<p>
								Over 2,500 dogs are enjoying a healthy diet with Pure after
								switching from pre-cooked.
							</p>
						)}
						{dietValue === "Other or mixed" && (
							<p>
								We offer full or part feeding plans so you can mix Pure with
								another food if you want to.
							</p>
						)}
					</div>
				</div>
			)}
		</div>
	);
};
