import ReactPaginate from "react-paginate";

import Styles from "./pagination.module.scss";

type Props = {
	totalPages: number;
	changePage: (data: any) => void;
};

export const Pagination = ({ totalPages, changePage }: Props) => {
	return (
		<ReactPaginate
			previousLabel={"<"}
			nextLabel={">"}
			breakLabel={"..."}
			breakClassName={Styles.break}
			pageCount={totalPages}
			marginPagesDisplayed={2}
			pageRangeDisplayed={5}
			onPageChange={changePage}
			containerClassName={Styles.pagination}
			activeClassName={Styles.active}
		/>
	);
};
