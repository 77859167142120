import c from "classnames";

import { DietComparison } from "@/components/DietComparison";
import { DietComparisonSection } from "@/types/page";

import Styles from "./dietComparison.module.scss";

type Props = {
	id: string;
	content?: DietComparisonSection;
};

export const DietComparisonContent = ({ id, content }: Props) => {
	return (
		<section id={id} className={c("sectionSpacing", Styles.dietComparison)}>
			<DietComparison
				allDiets={true}
				pageContent={content}
				diet={content.defaultDiet}
			/>
		</section>
	);
};
