import c from "classnames";

import { Icon } from "@/components/FormElements/Icon";
import { useMediaQueries } from "@/hooks/useMediaQueries";
import { Button } from "@/components/FormElements/Button";
import { SplitHeading } from "@/components/FormElements/SplitHeading";
import { SplitImage } from "@/components/SplitImage";
import { Accordion } from "@/components/FormElements/Accordion";
import { ButtonVariants } from "@/types/inputFields";
import { DietComparisonSection } from "@/types/page";

import Styles from "./DietComparison.module.scss";
import { useDietComparison } from "./useDietComparison";

export type DietProps = {
	allDiets?: boolean;
	diet?: string;
	name?: string;
	gender?: "male" | "female";
	pageContent?: DietComparisonSection;
};

export const DietComparison = ({
	diet,
	name,
	gender,
	allDiets = false,
	pageContent,
}: DietProps) => {
	const { content, food, setFood } = useDietComparison({
		allDiets,
		diet,
		name,
		gender,
	});

	const { isMobile } = useMediaQueries();

	const NoPetHeader = ({
		food,
		setFood,
	}: {
		setFood: (string: string) => void;
		food: string;
	}) => (
		<>
			<div className={Styles.tabs}>
				{pageContent?.heading?.[0] ? (
					<SplitHeading
						heirarchy={pageContent.heading[0].sizing || "h2"}
						line1={pageContent.heading[0].headingLine1}
						line2={pageContent.heading[0].headingLine2}
						line3={pageContent.heading[0].headingLine3}
						colour={pageContent.heading[0].secondaryLineColour}
						subtitle={pageContent.heading[0].subtitle}
					/>
				) : (
					<h2 className={Styles.genericTitle}>How Pure compares</h2>
				)}

				{pageContent?.description && (
					<p
						className="center paraMed"
						dangerouslySetInnerHTML={{ __html: pageContent.description }}
					/>
				)}

				<Button
					variant={
						food === "kibble biscuits"
							? ButtonVariants.default
							: ButtonVariants.clearLight
					}
					onClick={() => setFood("kibble biscuits")}
					width="relative"
				>
					Kibble biscuits
				</Button>
				<Button
					variant={
						food === "raw" ? ButtonVariants.default : ButtonVariants.clearLight
					}
					onClick={() => setFood("raw")}
					width="relative"
				>
					Raw
				</Button>
				<Button
					variant={
						food === "wet food"
							? ButtonVariants.default
							: ButtonVariants.clearLight
					}
					onClick={() => setFood("wet food")}
					width="relative"
				>
					Wet food
				</Button>
				<Button
					variant={
						food === "home prepared meals"
							? ButtonVariants.default
							: ButtonVariants.clearLight
					}
					onClick={() => setFood("home prepared meals")}
					width="relative"
				>
					Home cooked
				</Button>
				<Button
					variant={
						food === "pre-cooked food"
							? ButtonVariants.default
							: ButtonVariants.clearLight
					}
					onClick={() => setFood("pre-cooked food")}
					width="relative"
				>
					Pre cooked
				</Button>
			</div>
		</>
	);

	return (
		<div className={c("container", { [Styles["allDiets"]]: allDiets })}>
			{allDiets ? (
				<NoPetHeader food={food} setFood={setFood} />
			) : (
				<div className={Styles.heading}>
					<SplitHeading
						line1={content.title.line1}
						line2={content.title.line2}
					/>
					<p>{content.description}</p>
				</div>
			)}
			<div className={Styles.imageContainer}>
				<SplitImage
					diet={food}
					image1={content.image1}
					image1AltText={content.image1AltText}
					image2={content.image2}
					image2AltText={content.image2AltText}
				/>
			</div>
			<div className={Styles.posContainer}>
				{isMobile && <h3>{content.goodTitle}</h3>}
				{content.sections
					.filter((section: any) => !section.bad)
					.map((section: any, key: number) => {
						if (isMobile && !allDiets) {
							return (
								<Accordion
									leftAlign
									key={key}
									heading={
										<>
											<h4>
												<Icon icon={section.icon} />
												<span>{section.title}</span>
											</h4>
										</>
									}
								>
									<p>{section.content}</p>
								</Accordion>
							);
						}
						return (
							<div
								className={c(Styles.infoBlock, {
									[Styles.infoBlockSmall]: allDiets,
								})}
								key={key}
							>
								<h4>
									<Icon
										icon={section.icon}
										width={allDiets ? "36" : "24"}
										height={allDiets ? "36" : "24"}
									/>
									<span>{allDiets ? section.longTitle : section.title}</span>
								</h4>
								{!allDiets && <p>{section.content}</p>}
							</div>
						);
					})}
			</div>
			<div className={Styles.negContainer}>
				{isMobile && <h3>{content.badTitle}</h3>}
				{content.sections
					.filter((section: any) => section.bad)
					.map((section: any, key: number) => {
						if (isMobile && !allDiets) {
							return (
								<Accordion
									leftAlign
									isNegative
									key={key}
									heading={
										<>
											<h4>
												<Icon icon={section.icon} />
												<span>{section.title}</span>
											</h4>
										</>
									}
								>
									<p>{section.content}</p>
								</Accordion>
							);
						}
						return (
							<div
								className={c(Styles.infoBlock, {
									[Styles.infoBlockSmall]: allDiets,
								})}
								key={key}
							>
								<h4>
									<Icon
										icon={section.icon}
										width={allDiets ? "36" : "24"}
										height={allDiets ? "36" : "24"}
									/>
									<span>{allDiets ? section.longTitle : section.title}</span>
								</h4>
								{!allDiets && <p>{section.content}</p>}
							</div>
						);
					})}
			</div>
		</div>
	);
};
