import { useCallback, useEffect, useMemo, useState } from "react";

import { useForm } from "react-hook-form";
import { useRouter } from "next/router";

import { useFunnelStore } from "@/store/FunnelStore";
import { getBreedCount } from "@/API/pets";
import { DietOptions } from "@/pagesComponents/Funnel/steps/Diet/useDiet";
import { transformAge } from "@/helpers/helpers";
import { track } from "@/helpers/analytics";
import { QuickIntoFunnelWidget } from "@/types/page";
import { Fields, FunnelData } from "@/types/pet";

type FormData = {
	breed: string;
	diet: string;
	name: string;
	years: string;
	months: string;
	custom: string;
	transformedAge: string;
};

export const useQuickFunnelWidget = ({
	widget,
}: {
	widget: QuickIntoFunnelWidget;
}) => {
	const [breeds, fetchBreeds, initialisePet, resetFunnelData] = useFunnelStore(
		(state) => [
			state.breeds,
			state.fetchBreeds,
			state.initialisePet,
			state.resetFunnelData,
		]
	);

	const [breedCount, setBreedCount] = useState<{
		count: number;
		name: string;
	} | null>(null);

	const {
		register,
		handleSubmit,
		watch,
		setValue,
		getValues,
		formState: { errors },
	} = useForm<FormData>({
		mode: "onSubmit",
	});

	const router = useRouter();

	const breedValue = watch("breed", "");
	const dietValue = watch("diet", "");
	const yearsValue = watch("years", "");
	const monthsValue = watch("months", "");
	const customValue = watch("custom", "");

	const breedOptions = useMemo(() => {
		if (!breeds.length) {
			fetchBreeds();

			return [];
		}

		return breeds?.map((breed) => {
			return { value: breed.name, label: breed.name };
		});
	}, [breeds, fetchBreeds]);

	const dietOptions = useMemo(() => {
		return [
			...DietOptions.map((option) => {
				return { value: option.id, label: option.label };
			}),
			{ value: "other", label: "Other or mixed" },
		];
	}, []);

	const onChangeBreedSelect = useCallback(
		(val: string) => {
			setValue("breed", val);
		},
		[setValue]
	);

	const onChangeDietSelect = useCallback(
		(val: string, label?: string) => {
			setValue("diet", label || "");
		},
		[setValue]
	);

	const onChangeAge = useCallback(
		(months: string, years: string) => {
			const transformedAge = transformAge(parseInt(months), parseInt(years));

			setValue("years", years);
			setValue("months", months);
			setValue("transformedAge", `${transformedAge}`);
		},
		[setValue]
	);

	const onChangeYear = useCallback(
		(val: string) => {
			onChangeAge(monthsValue, val);
		},
		[monthsValue, onChangeAge]
	);

	const onChangeMonth = useCallback(
		(val: string) => {
			onChangeAge(val, yearsValue);
		},
		[onChangeAge, yearsValue]
	);

	const onChangeCustom = useCallback(
		(val: string | number | null) => {
			setValue("custom", val + "");
		},
		[setValue]
	);

	const onSubmit = useCallback(
		(formData: FormData) => {
			const initialPetData: Partial<FunnelData> = {};

			if (formData["name"]) {
				initialPetData[Fields.NAME] = formData["name"];
			}

			if (formData["breed"]) {
				initialPetData[Fields.BREED] = formData["breed"];
			}

			if (formData["diet"] && formData["diet"] !== "Other or mixed") {
				const diet = dietOptions.find(
					(option) => option.label === formData["diet"]
				)?.value;

				initialPetData[Fields.DIET] = diet ? [diet] : [];
			}

			let transformedAge;

			if (formData["years"] || formData["months"]) {
				const isMonthsNotSetYears0 =
					!formData["months"] &&
					formData["years"] &&
					`${formData["years"]}` === "0";

				const isYearsNotSetMonths0 =
					!formData["years"] &&
					formData["months"] &&
					`${formData["months"]}` === "0";

				const noformDataSet = !formData["years"] && !formData["months"];

				if (isMonthsNotSetYears0 || isYearsNotSetMonths0 || noformDataSet) {
					return;
				}

				transformedAge = transformAge(
					parseInt(formData["months"]),
					parseInt(formData["years"])
				);

				initialPetData[Fields.YEARS] = parseInt(formData["years"] + "");
				initialPetData[Fields.MONTHS] = parseInt(formData["months"] + "");
				initialPetData[Fields.TRANSFORMEDAGE] = transformedAge;
			}

			track("Use Quick Funnel Widget", {
				question: widget.title,
				type: widget.widgetType,
				choice:
					formData["name"] ||
					formData["breed"] ||
					formData["diet"] ||
					transformedAge ||
					formData["custom"],
			});

			resetFunnelData();

			initialisePet(formData["name"], initialPetData);

			formData["name"]
				? router.push("../signup/gender")
				: router.push("../signup");
		},
		[
			widget.title,
			widget.widgetType,
			resetFunnelData,
			initialisePet,
			router,
			dietOptions,
		]
	);

	useEffect(() => {
		if (
			!breedValue ||
			!breedOptions.find(
				({ label }) => label.toLowerCase() === breedValue.toLowerCase()
			)
		) {
			setBreedCount(null);

			return;
		}
		getBreedCount(breedValue).then((res) => {
			if (
				res.name?.toLowerCase() === "mixed breed" &&
				breedValue.toLowerCase() !== "mixed breed"
			) {
				return;
			}

			if (res?.total_count > 0) {
				setBreedCount({ count: res?.total_count, name: res?.name });

				return;
			}
			setBreedCount(null);
		});
	}, [breedOptions, breedValue]);

	const renderError = useCallback(() => {
		if (
			errors["years"]?.type === "pattern" ||
			errors["months"]?.type === "pattern"
		) {
			return "Age should be a number";
		}
		if (errors["years"] && errors["years"].type === "maxLength") {
			return "The age entered is too old";
		}
		if (!errors["years"] && errors["months"]?.type === "validate") {
			return "Months must not be greater than 11";
		}
		if (errors["years"]?.type === "maxLength") {
			return "Age is too high";
		}

		if (errors["months"]?.type === "maxLength") {
			return "Please enter a value for Months less than 99";
		}

		return `Please tell us how old your dog is`;
	}, [errors]);

	return {
		handleSubmit,
		breedOptions,
		onChangeBreedSelect,
		onChangeDietSelect,
		breedValue,
		register,
		onSubmit,
		breedCount,
		dietOptions,
		dietValue,
		monthsValue,
		yearsValue,
		getValues,
		onChangeYear,
		onChangeMonth,
		errors,
		renderError,
		customValue,
		onChangeCustom,
	};
};
