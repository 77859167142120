import Styles from "./splitImage.module.scss";
import { useSplitImage } from "./useSplitImage";

export type SplitImageProps = {
	diet: string;
	image1: string;
	image1AltText: string;
	image2: string;
	image2AltText: string;
};

export const SplitImage = ({
	diet,
	image1,
	image1AltText,
	image2,
	image2AltText,
}: SplitImageProps) => {
	const { dietSlug, fullImage, dividerLeft, dividerTop, clipPath } =
		useSplitImage({
			diet,
		});

	return (
		<div
			className={Styles.splitImage}
			id={Styles["funnel-" + dietSlug + "-image"]}
			ref={fullImage}
		>
			<span
				className={Styles.splitImageDivider}
				style={{ left: dividerLeft, top: dividerTop }}
			></span>
			<img
				src={image1}
				className={Styles.splitImageBottom}
				alt={image1AltText}
				height="600"
				width="600"
			/>
			<img
				src={image2}
				className={Styles.splitImageTop}
				style={{ clipPath: clipPath }}
				alt={image2AltText}
				height="600"
				width="600"
			/>
		</div>
	);
};
