import { useEffect, useRef } from "react";

import c from "classnames";

import { RadioOption as RadioOptionType } from "@/types/inputFields";

import Styles from "./radioOption.module.scss";

import { Icon } from "../Icon";

type Props = {
	option: RadioOptionType<unknown>;
	isSelected?: boolean;
	isToggle?: boolean;
	hasError?: boolean;
	register?: any;
	narrow?: boolean;
	amountOfOptions?: number;
	setActivePosition: (left: number) => void;
	onChange: (value: unknown) => void;
	required?: boolean;
	noCircle?: boolean;
};

export const RadioOption = ({
	option,
	isSelected,
	isToggle,
	hasError,
	register,
	narrow,
	setActivePosition,
	amountOfOptions,
	onChange,
	required,
	noCircle,
}: Props) => {
	const labelRef = useRef<HTMLLabelElement>(null);

	useEffect(() => {
		if (isSelected) {
			const left = labelRef.current?.offsetLeft;
			if (!left && left !== 0) {
				return;
			}
			setActivePosition(left);
		}
	}, [isSelected, setActivePosition]);

	const inputProps = onChange
		? { onClick: () => onChange(option.id), onChange: () => {} }
		: {};

	return (
		<label
			className={c({
				[Styles.radioItem]: !isToggle,
				[Styles.toggleItem]: isToggle,
				[Styles.noIcon]: !option.icon,
				[Styles.error]: hasError,
				[Styles.narrow]: narrow,
				[Styles.selected]: isSelected && !isToggle,
				[Styles.toggleSelected]: isSelected && isToggle,
				[Styles.dual]: amountOfOptions === 2,
				[Styles.trio]: amountOfOptions === 3,
				[Styles.quad]: amountOfOptions === 4,
				[Styles.noCircle]: noCircle,
			})}
			ref={labelRef}
		>
			{option.icon && (
				<div className={Styles.iconArea}>
					<Icon
						icon={option.icon.icon}
						height={option.icon.height}
						width={option.icon.width}
						heightToOccupy={option.icon.heightToOccupy}
						className={c({
							[Styles.icon]: true,
							[Styles.activeIcon]: isSelected,
						})}
					/>
				</div>
			)}
			<div className={Styles.inputArea}>
				<span className={c(Styles.input)}>
					<input
						type="radio"
						id={option.id}
						value={option.id}
						{...register}
						{...inputProps}
						required={required}
					/>
					<span className={Styles.statusOrb}></span>
				</span>
				<div className={Styles.label}>
					{option.label}
					{option.caption && (
						<div className={Styles.caption}>{option.caption}</div>
					)}
				</div>
			</div>
		</label>
	);
};
