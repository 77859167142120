import Link from "next/link";
import c from "classnames";

import { HeroSection } from "@/types/page";

import Styles from "./offerBanner.module.scss";

type Props = Omit<
	HeroSection["offerBannerContent"][number],
	"bannerHasCustomContent"
>;

export const OfferBannerCustomContent = ({
	hasIllustrations,
	link,
	textToUnderline,
	content,
	backgroundColor,
}: Props) => {
	const Content = textToUnderline
		? content.replace(
				textToUnderline,
				`<span class="underline">${textToUnderline}</span>`
			)
		: content;

	return (
		<section
			className={c(Styles.offerBanner, Styles[`background_${backgroundColor}`])}
		>
			<div>
				{!link ? (
					<div
						className={c("h4", Styles.content, {
							noIllustrations: !hasIllustrations,
						})}
						dangerouslySetInnerHTML={{ __html: Content }}
					></div>
				) : (
					<Link href={link}>
						<a
							className={c("h4", Styles.content, "noUnderline", {
								noIllustrations: !hasIllustrations,
							})}
							dangerouslySetInnerHTML={{ __html: Content }}
						></a>
					</Link>
				)}
			</div>
		</section>
	);
};
