import { useEffect, useState } from "react";

import c from "classnames";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";

import { HeroSection } from "@/types/page";
import { SplitHeading } from "@/components/FormElements/SplitHeading";
import { Button } from "@/components/FormElements/Button";
import { OfferBanner } from "@/components/OfferBanner/OfferBanner";
import { Trustpilot } from "@/components/Trustpilot/Trustpilot";
import { ButtonVariants } from "@/types/inputFields";
import { Icon } from "@/components/FormElements/Icon";
import { OfferBannerCustomContent } from "@/components/OfferBanner/OfferBannerCustomContent";
import { QuickFunnelWidget } from "@/components/QuickFunnelWidget/QuickFunnelWidget";
import { useABTest } from "@/hooks/useABTest";
import { Video } from "@/components/Video";
import { useMediaQueries } from "@/hooks/useMediaQueries";

import Styles from "./hero.module.scss";
type Props = {
	content: HeroSection;
	children?: React.ReactNode;
	page?: string;
};

export const Hero = ({ content, children, page }: Props) => {
	const { status } = useSession();

	const router = useRouter();

	const isXmasLanding = router.asPath.split("/")[1] === "christmas";

	const [testLoaded, setTestLoaded] = useState(false);
	const [ABTest, setABTest] = useState("Control");

	const { startABTest } = useABTest();

	const { isTablet } = useMediaQueries();

	// FUNNEL WIDGET AB TEST
	useEffect(() => {
		if (
			content.imageTest &&
			content.testName &&
			content.imageTestOptions &&
			(content.imageTestOptions?.length || 0) > 0
		) {
			const theABTest = startABTest({
				testName: content.testName,
				forceTest:
					process.env.NEXT_PUBLIC_URL === "https://localhost:3000" ||
					process.env.NEXT_PUBLIC_URL === "https://staging.purepetfood.com",
				variants: content.imageTestOptions.map((option, i) => {
					return { name: (content.testName || "") + " " + (i + 1) };
				}),
			});

			setABTest(theABTest);
		}

		setTestLoaded(true);
	}, [startABTest, content]);

	let theDesktopImage = content.desktopImage;
	let theMobileImage = content.mobileImage;

	if (content.imageTest && content.testName && content.imageTestOptions) {
		const heroImageTest = ABTest;
		if (heroImageTest && heroImageTest !== "Control") {
			const parts = heroImageTest.split(" ");
			const testNumber = parseInt(parts[parts.length - 1]);
			theDesktopImage = content.imageTestOptions[testNumber - 1].desktopImage;
			theMobileImage = content.imageTestOptions[testNumber - 1].mobileImage;
		}
	}

	return (
		<>
			<section className={Styles.hero} id={content.id}>
				{content.seasonalBanner && (
					<div className={Styles.xmasBanner}>
						<p>
							{isXmasLanding ? (
								<span>Existing customer?</span>
							) : (
								<>
									The perfect gifts <span>for your pup</span>
								</>
							)}
						</p>

						<Button
							variant={ButtonVariants.ghost}
							underline
							sizing="small"
							width="relative"
							url={
								status === "authenticated" || isXmasLanding
									? "/account/shop"
									: "/christmas"
							}
						>
							{isXmasLanding
								? "Log in for free delivery"
								: "Shop our festive range"}
						</Button>
						<div className={c(Styles.xmasBannerIcon, Styles.iconSantaHat)}>
							<Icon icon="SantaHat" width="56px" height="56px" />
						</div>
						<div className={c(Styles.xmasBannerIcon, Styles.iconBauble)}>
							<Icon icon="Bauble" width="72px" height="72px" />
						</div>
						<div className={c(Styles.xmasBannerIcon, Styles.iconSnowFlake)}>
							<Icon icon="SnowFlake" width="56px" height="56px" />
						</div>
						<div className={c(Styles.xmasBannerIcon, Styles.iconPresent)}>
							<Icon icon="Present" width="60px" height="60px" />
						</div>
					</div>
				)}
				{!content.heroAsVideo && (
					<div className={Styles.backgroundImage}>
						{(!content.imageTest || testLoaded) && (
							<picture>
								{theMobileImage?.responsiveImage?.src && (
									<source
										media="(max-width: 599px)"
										srcSet={theMobileImage.responsiveImage.src}
									/>
								)}
								{theDesktopImage?.responsiveImage?.src &&
									theDesktopImage?.responsiveImage?.alt && (
										<img
											src={theDesktopImage.responsiveImage.src}
											alt={theDesktopImage.responsiveImage.alt}
										/>
									)}
							</picture>
						)}
					</div>
				)}

				{content.heroAsVideo && (
					<div
						className={c({
							[Styles.backgroundVideo]: true,
						})}
					>
						<Video
							// Use isTablet, not is mobile. DO NOT CHANGE THIS ORDER.
							// Ideally we want to avoid loading 2 videos on mobile for speed, for desktop we can load 2.
							// By default, on page load the mediaQueries will always be false, so we will always
							// end up loading 2 videos, but we can choose which device to do that on.
							src={!isTablet ? content.mobileVideoUrl : content.desktopVideoUrl}
							id="heroImage"
							hasControls={content.detailedVideoControls && !content.autoplay}
							hidePlayBtn={false}
							autoplay={content.autoplay}
							muted={content.autoplay}
							autoplayWhenInView
							poster={
								(!isTablet
									? content.mobileThumbnail?.responsiveImage.src
									: content.desktopThumbnail?.responsiveImage.src) || undefined
							}
						/>
					</div>
				)}
				{!content.heroAsVideo && (
					<div className={"container"}>
						<div
							className={c(Styles.heroContent, {
								[Styles.heroContentLight]: content.darkImage,
							})}
						>
							{content?.heading?.[0]?.split ? (
								<SplitHeading
									heirarchy="h1"
									line1={content.heading[0].headingLine1}
									line2={content.heading[0].headingLine2 || ""}
									colour={content.heading[0].secondaryLineColour}
								/>
							) : (
								<h1>{content?.heading?.[0]?.headingLine1}</h1>
							)}
							<p className="h3">{content.description}</p>

							<div
								className={c(Styles.widgetTest, {
									[Styles.testNotLoaded]: !testLoaded,
								})}
							>
								{content?.quickIntoFunnelWidget?.[0] ? (
									<QuickFunnelWidget
										widget={content.quickIntoFunnelWidget[0]}
									/>
								) : (
									<>
										{content?.button?.[0]?.text && (
											<Button
												url={content.button[0].link}
												width="relative"
												sizing="large"
											>
												{content.button[0].text}
											</Button>
										)}
									</>
								)}
								{content.trustpilot && (
									<Trustpilot light={content.lightTrustpilot} />
								)}
							</div>
						</div>
						{children && children}
					</div>
				)}
			</section>

			{content.offerBanner &&
				(content.offerBannerContent?.[0]?.bannerHasCustomContent ? (
					<OfferBannerCustomContent {...content.offerBannerContent[0]} />
				) : (
					<OfferBanner />
				))}
		</>
	);
};
