import { useCallback, useEffect, useRef, useState } from "react";

import { SplitImageProps } from "./SplitImage";

export const useSplitImage = ({ diet }: Pick<SplitImageProps, "diet">) => {
	const dietSlug = diet.split(" ").join("-");
	const fullImage = useRef<HTMLDivElement>(null);
	const [dividerLeft, setDividerLeft] = useState("");
	const [dividerTop, setDividerTop] = useState("");
	const [clipPath, setClipPath] = useState("");

	const onScroll = useCallback(() => {
		const elementRef = (
			fullImage.current ? fullImage.current : fullImage
		) as HTMLDivElement;

		if (elementRef && elementRef.getBoundingClientRect) {
			const fromTop = elementRef.getBoundingClientRect().top;
			const screenHeight = window.innerHeight;
			const imageHeight = fullImage?.current?.offsetHeight;

			if (fromTop && screenHeight && imageHeight) {
				const scrollOfHeight = `${Math.abs(
					(fromTop - screenHeight) / (imageHeight + screenHeight)
				)}`;

				if (fromTop - screenHeight < 0 && parseInt(scrollOfHeight) < 1) {
					setDividerLeft(parseFloat(scrollOfHeight) * 75 - 41 + "%");
					setDividerTop(parseFloat(scrollOfHeight) * 25 + 35 + "%");
					setClipPath(
						"polygon(" +
							(parseFloat(scrollOfHeight) * 80 + 20) +
							"% 0%, 100% 0%, 100% 100%, " +
							parseFloat(scrollOfHeight) * 80 +
							"% 100%)"
					);
				}
			}
		}
	}, []);

	useEffect(() => {
		window.addEventListener("scroll", onScroll);

		return () => window.removeEventListener("scroll", onScroll);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		dietSlug,
		fullImage,
		dividerLeft: dividerLeft,
		dividerTop: dividerTop,
		clipPath: clipPath,
	};
};
