import { useEffect, useRef, useState } from "react";

import c from "classnames";

import Styles from "./helpCard.module.scss";

type Props = {
	children?: React.ReactNode;
	moving?: number;
	movingMax?: number;
	className?: string;
};

export const HelpCard = ({ children, moving, movingMax, className }: Props) => {
	const helpCard = useRef<HTMLDivElement>(null);
	const helpCardContainer = useRef<HTMLDivElement>(null);

	const [movingAmount, setMovingAmount] = useState(0);

	useEffect(() => {
		if ((!moving && moving !== 0) || (!movingMax && movingMax !== 0)) {
			return;
		}
		setMovingAmount(
			(moving *
				(100 -
					((helpCard.current?.offsetWidth || 0) /
						(helpCardContainer.current?.offsetWidth || 0)) *
						100)) /
				(movingMax - 1)
		);
		//}
	}, [moving, movingMax]);

	return (
		<div
			className={c({
				...(className ? { [className]: true } : {}),
			})}
			style={{ margin: 0 }}
			ref={helpCardContainer}
		>
			<div
				className={c(Styles.helpCard, {
					[Styles.helpCardMoving]: moving || moving === 0,
				})}
				style={{ left: movingAmount + "%" }}
				ref={helpCard}
			>
				{children}
			</div>
		</div>
	);
};
