import c from "classnames";
import slugify from "react-slugify";
import { Image } from "react-datocms";

import { QuotesSection } from "@/types/page";
import { SplitHeading } from "@/components/FormElements/SplitHeading";
import { Button } from "@/components/FormElements/Button";
import { Trustpilot } from "@/components/Trustpilot/Trustpilot";

import Styles from "./quotes.module.scss";

type Props = {
	content: QuotesSection;
};

export const Quotes = ({ content }: Props) => {
	const TrustPilotSection = content?.heading?.[0]?.showTrustPilotReviews ? (
		<Trustpilot
			centered
			showReviewCount={content?.heading?.[0]?.showTrustPilotCount}
		/>
	) : null;

	return (
		<section
			id={content.id}
			className={c("sectionSpacing", Styles.quotes, {
				[Styles["border-" + slugify(content.border)]]: content.border,
			})}
		>
			<div className={c("container")}>
				{content?.heading?.[0]?.headingLine1 && (
					<>
						{content.heading[0].split ? (
							<SplitHeading
								heirarchy={content.heading[0].sizing}
								line1={content.heading[0].headingLine1}
								line2={content.heading[0].headingLine2}
								colour={content.heading[0].secondaryLineColour}
							>
								{TrustPilotSection}
							</SplitHeading>
						) : (
							<>
								<h2>{content.heading[0].headingLine1}</h2>
								{TrustPilotSection}
							</>
						)}
					</>
				)}

				{content.quotes?.map((quote, key) => (
					<div
						className={c(Styles.quote, {
							[Styles.singleQuote]: content.quotes?.length === 1,
						})}
						key={key}
					>
						<div className={Styles.quoteImage}>
							<Image data={quote.image.responsiveImage} />
						</div>
						<div className={Styles.quoteContent}>
							<span
								className={Styles.quoteContentQuote}
								dangerouslySetInnerHTML={{ __html: quote.quote }}
							/>
							<p>
								<strong>{quote.quoter}</strong>
							</p>
						</div>
					</div>
				))}

				{content?.button?.[0]?.text && (
					<Button url={content.button[0].link} width="relative" sizing="large">
						{content.button[0].text}
					</Button>
				)}
			</div>
		</section>
	);
};
