import { useState } from "react";

import c from "classnames";
import { Image } from "react-datocms";

import { SuccessStoriesSection, SuccessStory } from "@/types/page";
import { SplitHeading } from "@/components/FormElements/SplitHeading";
import { Button } from "@/components/FormElements/Button";
import { trim } from "@/helpers/helperFunctions";
import { ButtonVariants } from "@/types/inputFields";
import { Pagination } from "@/components/Pagination/Pagination";

import Styles from "./successStories.module.scss";

type Props = {
	content: SuccessStoriesSection;
};

type StoryProps = {
	story: SuccessStory;
};

const SuccessStory = ({ story }: StoryProps) => {
	const [storyOpen, setStoryOpen] = useState(false);

	return (
		<div className={c(Styles.successStory)}>
			{story.coverImage?.[0]?.image && (
				<div className={Styles.successStoryImage}>
					<Image data={story.coverImage[0].image.responsiveImage} />
				</div>
			)}
			<div className={Styles.successStoryContent}>
				<div className={Styles.successStoryPetName}>
					{story.ownerName} & {story.petName}
				</div>
				{storyOpen ? (
					<p>{story.description}</p>
				) : (
					<p>{trim(story.description, 160)}</p>
				)}

				<Button
					variant={ButtonVariants.ghost}
					width="relative"
					onClick={() => setStoryOpen(!storyOpen)}
				>
					Show {storyOpen ? "less" : "more"}
				</Button>
			</div>
		</div>
	);
};

export const SuccessStories = ({ content }: Props) => {
	const [showing, setShowing] = useState(0);
	const [pagination, setPagination] = useState(0);

	return (
		<section
			id={content.id}
			className={c("sectionSpacing", Styles.successStories)}
		>
			<div className={c("container")}>
				{content?.heading?.[0]?.headingLine1 && (
					<>
						{content.heading[0].split ? (
							<SplitHeading
								heirarchy={content.heading[0].sizing}
								line1={content.heading[0].headingLine1}
								line2={content.heading[0].headingLine2 || ""}
								colour={content.heading[0].secondaryLineColour}
							/>
						) : (
							<h2>{content.heading[0].headingLine1}</h2>
						)}
					</>
				)}

				<div className={Styles.categories}>
					{content.successStoryCategories?.map((cat, key) => (
						<Button
							variant={
								key === showing
									? ButtonVariants.clearAlternate
									: ButtonVariants.clearLight
							}
							key={key}
							width="relative"
							onClick={() => setShowing(key)}
						>
							{cat.categoryTitle}
						</Button>
					))}
				</div>

				{content.successStoryCategories
					?.filter((cat, key) => key === showing)
					?.map((cat) => (
						<>
							{cat?.successStories
								?.filter(
									(story, key) => key < pagination + 4 && key >= pagination
								)
								?.map((story, key) => (
									<div className={Styles.successStoryCont} key={key}>
										<SuccessStory story={story} />
									</div>
								))}
						</>
					))}

				<Pagination
					totalPages={Math.ceil(
						(content.successStoryCategories?.find((cat, key) => key === showing)
							?.successStories?.length || 4) / 4
					)}
					changePage={(data: { selected: number }) => {
						setPagination(data.selected * 4);
					}}
				/>
			</div>
		</section>
	);
};
